<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                Incomplete orders
                <div class="form-group ml-auto mb-0" style="width: 195px">
                    <input type="text"
                           class="form-control form-control-sm my-auto"
                           v-model="searchText"
                           placeholder="Search"/>
                </div>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <span v-if="!loading && !orders.length">No information to display</span>
                <table id="orders-table"
                       v-show="!loading && orders.length"
                       class="table table-sm table-borderless table-fixed">
                    <thead>
                        <tr>
                            <th>BC ID</th>
                            <th>Status</th>
                            <th>Received Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr @click="routeToOrder(order)"
                            class="table-row"
                            v-for="order in orders"
                            :key="order.id">
                            <td>{{ order.big_commerce_order_id }}</td>
                            <td>{{ order.status.name }}</td>
                            <td>{{ formatDate(order.created_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getOrders } from '../../services/OrderService';
import dataTableMixin from '../../mixins/dataTable';

import Spinner from 'vue-simple-spinner';

export default {
    name: 'OrderList',
    components: {
        Spinner
    },
    mixins: [dataTableMixin],
    data() {
        return {
            orders: [],
            loading: false
        };
    },
    mounted() {
        this.getOrders();
    },
    methods: {
        async getOrders() {
            try {
                this.loading = true;
                const response = await getOrders('incomplete');
                this.orders = response.data;
                this.createDataTable('#orders-table');
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        routeToOrder(order) {
            if ([3, 11].includes(order.status.id)) {
                this.$router.push({ name: 'order-fulfillment', params: { id: order.id } });
            } else {
                this.$router.push({
                    name: 'shipments-list',
                    params: { bc_order_id: order.big_commerce_order_id }
                });
            }
        },
        formatDate(date) {
            return moment(date).format('ll');
        }
    }
};
</script>
